<template>
  <div id="map" ref="mapMiQIMS" class="map">

    <!-- Top Left Panel -->
    <v-layout column class="top-left">


      <!-- Search Button -->
      <v-menu v-model="modelContentSearch" :close-on-content-click="false" max-width="280" transition="slide-x-transition"
        offset-x nudge-right="12" right>
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip right v-bind="attrs" v-on="on">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab small color="white" class="mt-2 primary--text" plain v-bind="attrs" v-on="on"
                @click="map_search()">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
        </template>

        <v-card class="pa-3 px-5">
          <v-row v-show="stationCurrentMode.a == true && stationCurrentMode.m == true">
            <v-col>
              <v-radio-group v-model="modelRadioSearchLayer" hide-details class="mt-0" dense row
                @change="selectRadioSearchLayer(modelRadioSearchLayer)">
                <v-radio label="Continuous" value="Conti"></v-radio>
                <v-radio label="Manual" value="Manual"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select :items="listSearchStates" v-model="modelSearchStateSelected" label="State"
                prepend-inner-icon="mdi-earth-box" hide-details dense solo
                @change="selectSearchStates(modelSearchStateSelected)"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select :items="listSearchStation" v-model="modelSearchStationSelected" label="Station"
                prepend-inner-icon="mdi-store-marker" :disabled="modelSearchStateSelected == ''" hide-details dense solo
                @change="selectSearchStation(modelSearchStationSelected)"></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-menu>


      <!-- Home Button -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small color="white" class="mt-3 primary--text" plain v-bind="attrs" v-on="on" @click="map_home()">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </template>
        <span>Home</span>
      </v-tooltip>


      <!-- Basemap Button -->
      <v-menu v-model="modelContentBasemap" :close-on-content-click="false" :nudge-width="200"
        transition="slide-x-transition" class="mp-2" offset-x nudge-right="12" right>
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip right v-bind="attrs" v-on="on">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab small color="white" class="mt-3 primary--text" plain v-bind="attrs" v-on="on"
                @click="modelContentBasemap = true">
                <v-icon>mdi-map</v-icon>
              </v-btn>
            </template>
            <span>Basemap</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-list class="pa-0">
            <v-list-item v-for="(item, i) in listBasemap" :key="i" class="v-list-item-basemap" @click="map_basemap(item)">
              <v-list-item-avatar size="50" :style="item.name == basemapSelected ? 'box-shadow:0px 0px 3px 4px;' : ''">
                <img :src="item.src">
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>


      <!-- Information Button -->
      <v-menu v-model="modelContentInformation" :close-on-content-click="false" :nudge-width="200"
        transition="slide-x-transition" class="mp-2" offset-x nudge-right="12" right>
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip right v-bind="attrs" v-on="on">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab small color="white" class="mt-3 primary--text" plain v-bind="attrs" v-on="on"
                @click="modelContentInformation = true">
                <v-icon>mdi-information-variant</v-icon>
              </v-btn>
            </template>
            <span>Information</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-list class="pa-0">
            <v-list-item v-for="(item, i) in listLegend" :key="i" class="v-list-item-information" dense>
              <v-list-item-avatar size="50">
                <img :src="item.img">
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>


      <!-- Point Of Interest Button -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small :color="poiSelected == true ? 'primary' : 'white'"
            :class="poiSelected == true ? 'mt-3 white--text' : 'mt-3 primary--text'" plain v-bind="attrs" v-on="on"
            @click="map_poi()">
            <v-icon>mdi-map-marker</v-icon>
          </v-btn>
        </template>
        <span>Point Of Interest</span>
      </v-tooltip>


      <!-- Continuous Station Button -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small :color="stationCurrentMode.a == true ? 'primary' : 'white'"
            :class="stationCurrentMode.a == true ? 'mt-3 white--text' : 'mt-3 primary--text'" plain v-bind="attrs"
            v-on="on" @click="map_station('Conti')">
            <h2><strong>C</strong></h2>
          </v-btn>
        </template>
        <span>Continuous Station</span>
      </v-tooltip>


      <!-- Manual Station Button -->
      <!-- comment -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small :color="stationCurrentMode.m == true ? 'primary' : 'white'"
            :class="stationCurrentMode.m == true ? 'mt-3 white--text' : 'mt-3 primary--text'" plain v-bind="attrs"
            v-on="on" @click="map_station('Manual')">
            <h2><strong>M</strong></h2>
          </v-btn>
        </template>
        <span>Manual Station</span>
      </v-tooltip>


      <!-- Investigative Study Station Button -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small :color="stationCurrentMode.is == true ? 'primary' : 'white'"
            :class="stationCurrentMode.is == true ? 'mt-3 white--text' : 'mt-3 primary--text'" plain v-bind="attrs"
            v-on="on" @click="map_station('Invest Study')">
            <h2><strong>IS</strong></h2>
          </v-btn>
        </template>
        <span>Investigative Study</span>
      </v-tooltip>


      <!-- Baseline Study Station Button -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small :color="stationCurrentMode.bs == true ? 'primary' : 'white'"
            :class="stationCurrentMode.bs == true ? 'mt-3 white--text' : 'mt-3 primary--text'" plain v-bind="attrs"
            v-on="on" @click="map_station('Baseline Study')">
            <h2><strong>BS</strong></h2>
          </v-btn>
        </template>
        <span>Baseline Study</span>
      </v-tooltip>

    </v-layout>




    <!-- Top Right Panel -->
    <v-layout column class="top-right">

      <!-- Display Station Detaails from Identify Action -->
      <v-menu v-model="modelContentStationIdentify" :close-on-click="false" :close-on-content-click="false"
        max-width="610" min-width="610" max-height="650" min-height="650" transition="slide-x-transition" class="mt-2"
        attach=".top-right" left location="bottom end">

        <v-card class="pa-1">

          <v-card-title v-show="detailsIdentifyVectorSelected.group == 'POI'" class="pa-1 px-3 card-header">

            <v-spacer></v-spacer>

            <v-tooltip bottom>

              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" dark icon small @click="modelContentStationIdentify = false">
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </template>

              Close

            </v-tooltip>

            <v-card-text>
              <v-list-item dark three-line class="pl-0 pr-0">

                <v-list-item-content class="text-subtitle-1 ">
                  <v-row>
                    <v-col>
                      <v-icon class="mr-2" x-large>
                        mdi-map-marker-multiple
                      </v-icon>
                      Point of Interest
                    </v-col>
                  </v-row>
                </v-list-item-content>


                <v-list-item-content>
                  <v-row>
                    <v-col>
                      Point of Interest Name : {{ detailsIdentifyVectorSelected.label }}
                    </v-col>
                  </v-row>
                </v-list-item-content>

              </v-list-item>
            </v-card-text>



          </v-card-title>

          <v-card-title v-show="detailsIdentifyVectorSelected.group != 'POI'" class="pa-1 card-header">

            <v-spacer></v-spacer>

            <v-tooltip bottom>

              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" dark icon small @click="closeModelContentStationIdentify()">
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </template>

              Close

            </v-tooltip>

            <v-card-text>
              <v-list-item three-line dark class="pl-0 pr-0">

                <!-- <v-list-item-avatar dense class="mr-2">
                  
                </v-list-item-avatar> -->
                <!-- <v-list-item-content class="text-subtitle-1 "> -->

                <!-- </v-list-item-content> -->

                <v-list-item-content class="text-subtitle-1 ">
                  <v-row>
                    <v-col v-if="stationCurrentMode.is == false" class="d-flex">
                      <v-icon dense class="mr-2">
                        mdi-map-marker-multiple
                      </v-icon>
                      STATION: {{ detailsIdentifyVectorSelected.stationID }}
                    </v-col>
                    <v-col v-else-if="stationCurrentMode.is == true" class="d-flex">
                      <v-icon dense class="mr-2">
                        mdi-map-marker-multiple
                      </v-icon>
                      CASE ID: {{ detailsIdentifyVectorSelected.stationID }}
                    </v-col>
                  </v-row>
                </v-list-item-content>


                <v-list-item-content v-if="stationCurrentMode.is == false">
                  <v-row>
                    <v-col>
                      Location : {{ detailsIdentifyVectorSelected.location }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      Last Updated:
                      <!-- <br> -->
                      {{
                        new Date(detailsIdentifyVectorSelected.datetime).toLocaleDateString("en-GB") +
                        ", " +
                        new Date(detailsIdentifyVectorSelected.datetime).toLocaleTimeString()
                      }}
                    </v-col>
                  </v-row>
                </v-list-item-content>

                <v-list-item-content v-if="stationCurrentMode.is == true">
                  <v-row>
                    <v-col>
                      Case Name : {{ detailsIdentifyVectorSelected.others.CaseName }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      Case Title : {{ detailsIdentifyVectorSelected.others.CaseTitle }}
                    </v-col>
                  </v-row>
                </v-list-item-content>

              </v-list-item>
            </v-card-text>

          </v-card-title>

          <v-card-text class="px-2 pb-0">
            <v-row>
              <v-col>

                <v-expansion-panels v-model="modelContentExpansionIdentify">

                  <!-- Parameter Content -->
                  <v-expansion-panel
                    v-show="detailsIdentifyVectorSelected.group == 'Conti' || detailsIdentifyVectorSelected.group == 'Manual' || detailsIdentifyVectorSelected.group == 'BaselineStudy'"
                    expand>
                    <v-expansion-panel-header>
                      <span class="font-weight-bold">Parameter</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card>
                        <v-simple-table fixed-header
                          :height="detailsIdentifyVectorSelected.group == 'Conti' ? '250' : '230'" dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-for="(item, i) in theadContentParamsIndentify" :key="i" class="text-left"
                                  style="background:#1476bf;color:white;white-space: nowrap;">
                                  {{ item }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, x) in tbodyContentParamsIndentify" :key="x"
                                :class="item.type != '' ? item.type == 'INSITU' ? 'in-situ' : item.type == 'LAB' ? 'laboratory' : '' : ''">
                                <td class="text-center">
                                  <!-- <span>{{ item.status }}</span> -->
                                  <v-icon v-if="item.status == 'I'" dense class="light-blue--text">mdi-circle</v-icon>
                                  <v-icon v-else-if="item.status == 'II'" dense class="success--text">mdi-circle</v-icon>
                                  <v-icon v-else-if="item.status == 'III'" dense class="yellow--text">mdi-circle</v-icon>
                                  <v-icon v-else-if="item.status == 'IV'" dense class="orange--text">mdi-circle</v-icon>
                                  <v-icon v-else-if="item.status == 'V'" dense class="red--text">mdi-circle</v-icon>
                                  <v-icon v-else-if="item.status == 'NA'" dense class="grey--text">mdi-circle</v-icon>
                                </td>
                                <td>{{ item.fullname }}</td>
                                <td>
                                  <span v-if="item.flag != undefined">
                                    <span v-if="item.flag == null || item.flag == ''">
                                      <span
                                        v-if="(item.value_unit != null && item.value_unit != '') && item.unit != null">
                                        {{ item.value_unit }} {{ item.unit }}
                                      </span>
                                      <span v-else-if="item.value_unit == null">
                                        {{ item.value_unit }}
                                      </span>
                                    </span>
                                    <span v-else class="red--text font-weight-bold">
                                      {{ item.flag }}
                                    </span>
                                  </span>
                                  <span v-else>
                                    <span v-if="(item.value_unit != null && item.value_unit != '') && item.unit != null">
                                      {{ item.value_unit }} {{ item.unit }}
                                    </span>
                                    <span v-else>
                                      {{ item.value_unit }}
                                    </span>
                                  </span>
                                </td>
                                <td class="text-center">{{ item.sub_index }}</td>
                                <td class="text-center">
                                  <span v-if="item.effluent_guideline != null">
                                    {{ item.effluent_guideline }} {{ item.unit }}
                                  </span>
                                  <span v-else-if="item.effluent_guideline == null">
                                    {{ item.effluent_guideline }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>

                        <p v-if="detailsIdentifyVectorSelected.group == 'Manual' || detailsIdentifyVectorSelected.group == 'BaselineStudy'"
                          style="font-size:0.90em;text-align:left;" class="pa-2">
                          <b>
                            Notes:
                            <v-icon color="insitu" class="ml-2" dense>mdi-circle</v-icon> <span
                              class="mr-5">In-situ</span>
                            <v-icon color="lab" dense>mdi-circle</v-icon> <span>Laboratory</span>
                          </b>
                        </p>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- Station Content -->
                  <v-expansion-panel expand
                    :readonly="detailsIdentifyVectorSelected.group != 'Conti' && detailsIdentifyVectorSelected.group != 'Manual' && detailsIdentifyVectorSelected.group != 'BaselineStudy'">
                    <v-expansion-panel-header
                      :hide-actions="detailsIdentifyVectorSelected.group != 'Conti' && detailsIdentifyVectorSelected.group != 'Manual' && detailsIdentifyVectorSelected.group != 'BaselineStudy'">
                      <span v-if="detailsIdentifyVectorSelected.group == 'POI'" class="font-weight-bold">
                        Point of Interest Details
                      </span>
                      <span v-else class="font-weight-bold">
                        Station Details
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-3" style="height:270px;overflow:auto;">

                      <v-row v-show="detailsIdentifyVectorSelected.group == 'POI'">
                        <v-col cols="4">
                          Name
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.label }}
                        </v-col>
                      </v-row>

                      <v-row
                        v-show="detailsIdentifyVectorSelected.group == 'Conti' || detailsIdentifyVectorSelected.group == 'Manual' || detailsIdentifyVectorSelected.group == 'BaselineStudy'">
                        <v-col cols="4">
                          Station ID
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.stationID }}
                        </v-col>
                      </v-row>

                      <v-row v-show="detailsIdentifyVectorSelected.group == 'InvestStudy'">
                        <v-col cols="4">
                          Case ID
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.caseID }}
                        </v-col>
                      </v-row>

                      <v-row
                        v-show="detailsIdentifyVectorSelected.group == 'Conti' || detailsIdentifyVectorSelected.group == 'Manual' || detailsIdentifyVectorSelected.group == 'BaselineStudy'">
                        <v-col cols="4">
                          Station Location
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.location }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4">
                          Longitude
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.long }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4">
                          Latitude
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.lat }}
                        </v-col>
                      </v-row>

                      <!-- <v-row v-show="detailsIdentifyVectorSelected.group == 'Conti'">
                        <v-col cols="4">
                          Sonde Depth (m)
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.DEPTH == null ? '-' : detailsIdentifyVectorSelected.DEPTH }}
                        </v-col>
                      </v-row>

                      <v-row v-show="detailsIdentifyVectorSelected.group == 'Conti'">
                        <v-col cols="4">
                          Battery (V)
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.BATTERY == null ? '-' :
                            detailsIdentifyVectorSelected.BATTERY }}
                        </v-col>
                      </v-row> -->

                      <v-row v-show="detailsIdentifyVectorSelected.group == 'POI'">
                        <v-col cols="4">
                          Description
                        </v-col>
                        <v-col>
                          : {{ detailsIdentifyVectorSelected.others }}
                        </v-col>
                      </v-row>

                      <v-row v-show="detailsIdentifyVectorSelected.group == 'InvestStudy'">
                        <v-col cols="4" class="pb-0 text-decoration-underline">
                          Sampling Point
                        </v-col>
                        <v-col class="pb-0">
                          :
                        </v-col>
                      </v-row>
                      <v-row v-show="detailsIdentifyVectorSelected.group == 'InvestStudy'">
                        <v-col class="pt-0">
                          <v-switch v-model="modelSwitchSamplePointInvestStudy" hide-details inset dense
                            :loading="loadingSwitchSamplePointInvestStudy"
                            label="Switch on to display sampling point on map."
                            @change="swichingSamplePointInvestStudy(detailsIdentifyVectorSelected.caseID, modelSwitchSamplePointInvestStudy)"></v-switch>
                        </v-col>
                      </v-row>

                      <v-row v-show="modelSwitchSamplePointInvestStudy == true">
                        <v-col class="px-0">

                          <v-card class="pa-3" outlined elevation="3">

                            <v-row>
                              <v-col class="pb-5 text-decoration-underline font-weight-bold">
                                Sample Point Information
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="4" class="pb-0">
                                Point ID
                              </v-col>
                              <v-col>
                                : {{ detailsSamplePointSelected.ID }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="4" class="pb-0">
                                State
                              </v-col>
                              <v-col>
                                : {{ detailsSamplePointSelected.State }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="4" class="pb-0">
                                Longitude
                              </v-col>
                              <v-col>
                                : {{ detailsSamplePointSelected.Long }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="4" class="pb-0">
                                Latitude
                              </v-col>
                              <v-col>
                                : {{ detailsSamplePointSelected.Lat }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="4" class="pb-0">
                                Sampling Picture
                              </v-col>
                              <v-col>
                                :
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <v-img max-height="150" max-width="150" :src="detailsSamplePointSelected.Image"></v-img>
                                <!-- {{ detailsSamplePointSelected.Image }} -->
                              </v-col>
                            </v-row>
                          </v-card>

                        </v-col>
                      </v-row>

                      <v-row v-show="detailsIdentifyVectorSelected.group == 'POI'">
                        <v-col cols="4">
                          Point of Interest Picture
                        </v-col>
                        <v-col>
                          <span
                            v-if="detailsIdentifyVectorSelected.img != undefined && detailsIdentifyVectorSelected.img != ''">
                            <v-img height="200" width="200" max-height="160" max-width="250"
                              :src="detailsIdentifyVectorSelected.img"></v-img>
                          </span>
                          <span v-else>
                            : None


                          </span>
                        </v-col>
                      </v-row>

                      <v-row
                        v-show="detailsIdentifyVectorSelected.group != 'POI' && detailsIdentifyVectorSelected.group != 'InvestStudy'">
                        <v-col cols="4">
                          Station Picture
                        </v-col>
                        <v-col>
                          <span
                            v-if="detailsIdentifyVectorSelected.stationImage.station == '' && detailsIdentifyVectorSelected.stationImage.deployment == '' && detailsIdentifyVectorSelected.stationImage.upstream == '' && detailsIdentifyVectorSelected.stationImage.downstream == '' || detailsIdentifyVectorSelected.stationImage.station == null && detailsIdentifyVectorSelected.stationImage.deployment == null && detailsIdentifyVectorSelected.stationImage.upstream == null && detailsIdentifyVectorSelected.stationImage.downstream == null">
                            : None
                          </span>
                          <span v-else>
                            :
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="justify-center align-center"
                        v-show="detailsIdentifyVectorSelected.group != 'POI' && detailsIdentifyVectorSelected.group != 'InvestStudy'">
                        <v-col cols="auto"
                          v-if="detailsIdentifyVectorSelected.stationImage.station != '' && detailsIdentifyVectorSelected.stationImage.deployment != null">
                          <v-img height="160" width="160" max-height="160" max-width="250" class="d-flex justify-center"
                            @click="openNewTab(detailsIdentifyVectorSelected.stationImage.station)"></v-img>
                          <div class="text-center">Station</div>
                        </v-col>
                        <v-col cols="auto"
                          v-if="detailsIdentifyVectorSelected.stationImage.deployment != '' && detailsIdentifyVectorSelected.stationImage.deployment != null">
                          <v-img height="160" width="160" max-height="160" max-width="250"
                            :src="detailsIdentifyVectorSelected.stationImage.deployment"
                            @click="openNewTab(detailsIdentifyVectorSelected.stationImage.deployment)"></v-img>
                          <div class="text-center">Deployment</div>

                        </v-col>
                        <v-col cols="auto"
                          v-if="detailsIdentifyVectorSelected.stationImage.upstream != '' && detailsIdentifyVectorSelected.stationImage.deployment != null">
                          <v-img height="160" width="160" max-height="160" max-width="250"
                            :src="detailsIdentifyVectorSelected.stationImage.upstream"
                            @click="openNewTab(detailsIdentifyVectorSelected.stationImage.upstream)"></v-img>
                          <div class="text-center">Upstream</div>

                        </v-col>
                        <v-col cols="auto"
                          v-if="detailsIdentifyVectorSelected.stationImage.downstream != '' && detailsIdentifyVectorSelected.stationImage.deployment != null">
                          <v-img height="160" width="160" max-height="160" max-width="250"
                            :src="detailsIdentifyVectorSelected.stationImage.downstream"
                            @click="openNewTab(detailsIdentifyVectorSelected.stationImage.downstream)"></v-img>
                          <div class="text-center">Downstream</div>


                        </v-col>
                      </v-row>

                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>

              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-menu>

    </v-layout>

  </div>
</template>

<script>
import axios from "axios";
import { bus } from '@/main';

import "ol/ol.css"
import Map from "ol/Map";
import View from "ol/View";
import { OSM, ImageWMS, Vector as VectorSource } from 'ol/source';
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as GroupLayer,
} from 'ol/layer';
import { Icon, Style, Stroke, Circle, Fill, Text } from 'ol/style';
import { GeoJSON } from 'ol/format';
import * as olExtent from 'ol/extent';
import {
  defaults as defaultControls,
  ScaleLine,
  FullScreen
} from "ol/control";

export default {

  data: () => ({

    // General
    stationCurrentMode: {
      a: true,
      m: false,
      is: false,
      bs: false,
    },
    dataParams: [
      {
        module: "Conti",
        data: []
      },
      {
        module: "Manual",
        data: []
      },
      {
        module: "InvestStudy",
        data: []
      },
      {
        module: "BaselineStudy",
        data: []
      },
    ],



    // General Map
    map: null,
    view: null,
    detailsIdentifyVectorSelected: {
      stationID: "",
      location: "",
      datetime: "",
      others: "",
      img: "",
      stationImage: {
        station: "",
        deployment: "",
        upstream: "",
        downstream: "",
      },
      group: "",
    },
    extentMalaysia: [
      93.18809115152676,
      -5.792451286783663,
      123.55430208902676,
      13.257841681966337
    ],
    dataGlobalStations: [
      {
        module: "POI",
        data: []
      },
      {
        module: "Conti",
        data: []
      },
      {
        module: "Manual",
        data: []
      },
      {
        module: "InvestStudy",
        data: []
      },
      {
        module: "BaselineStudy",
        data: []
      },
    ],
    objGeom: {},

    // Identify
    theadContentParamsIndentify: [
      " ",
      "Parameter",
      "Value & Unit",
      "Sub Index",
      "Effluent Guideline",
    ],
    tbodyContentParamsIndentify: [],
    modelContentExpansionIdentify: 0,
    detailsSamplePointSelected: {
      ID: "",
      Location: "",
      State: "",
      Date: "",
      Status: "",
      Lat: "",
      Long: "",
      Image: "",
      Others: "",
      Group: "",
    },



    // Search
    modelRadioSearchLayer: "",
    modelContentSearch: false,
    listSearchStates: [],
    modelSearchStateSelected: "",
    listSearchStation: [],
    modelSearchStationSelected: "",
    extentState: [
      {
        name: "JOHOR",
        extent: [101.75131845249587, 0.9648397067421169, 105.03228642718585, 2.936787745410824]
      },
      {
        name: "KEDAH",
        extent: [99.18237832805765, 4.868878185804961, 102.27289842282222, 6.726362040066922]
      },
      {
        name: "KELANTAN",
        extent: [100.48662704604496, 4.22756904514186, 104.12036122778761, 6.411538851615798]
      },
      {
        name: "MELAKA",
        extent: [101.87990747321184, 2.0140964128687537, 102.74770036995118, 2.535662765815993]
      },
      {
        name: "NEGERI SEMBILAN",
        extent: [101.23197559573036, 2.311199386709852, 103.00206901676957, 3.375072084281202]
      },
      {
        name: "PAHANG",
        extent: [101.32164875634818, 2.1705664481059337, 104.54518359319592, 4.107995660502555]
      },
      {
        name: "PULAU PINANG",
        extent: [99.95865819539875, 5.07536846147747, 100.82096527482321, 5.593637693947454],
      },
      {
        name: "PERAK",
        extent: [99.19376717126754, 3.107394301718, 103.93947091127147, 6.221346965852233]
      },
      {
        name: "PERLIS",
        extent: [99.79007647773318, 6.206554668803961, 100.66984732426624, 6.73532008458425]
      },
      {
        name: "SABAH",
        extent: [113.46796387834475, 3.3194714263250154, 120.12397229526154, 7.31990753065537]
      },
      {
        name: "SARAWAK",
        extent: [95.57959422793664, -7.150926608313, 123.04048056572617, 10.8678762617912]
      },
      {
        name: "SELANGOR",
        extent: [100.2290452924926, 2.622573633094058, 102.47532843128863, 4.096500314502931]
      },
      {
        name: "TERENGGANU",
        extent: [100.844627539974, 3.4080101841022903, 104.7366502371485, 5.9618093908617436]
      },
      {
        name: "WP KUALA LUMPUR",
        extent: [101.25701986175493, 2.820524480563135, 102.11091098655321, 3.3808157858404355]
      },
      {
        name: "WP LABUAN",
        extent: [115.04727368923399, 5.183495225394548, 115.39458171451717, 5.4113857153737825]
      },
      {
        name: "WP PUTRAJAYA",
        extent: [101.39872332436629, 2.763936225159858, 101.9138387898793, 3.101935658763293]
      },
    ],



    // Basemap
    modelContentBasemap: false,
    basemapSelected: "Topographic Map",
    listBasemap: [
      {
        src: require("@/assets/basemap/topographicMap.png"),
        name: "Topographic Map",
        layer: "topographicMap",
      },
      {
        src: require("@/assets/basemap/esriSateliteMap.png"),
        name: "Esri Satellite Map",
        layer: "esriSateliteMap",
      },
      {
        src: require("@/assets/basemap/googleStreetMap.png"),
        name: "Google Street Map",
        layer: "googleStreetMap",
      },
      {
        src: require("@/assets/basemap/streetMap.png"),
        name: "Street Map",
        layer: "streetMap",
      },
      {
        src: require("@/assets/basemap/terrainMap.png"),
        name: "Terrain Map",
        layer: "terrainMap",
      },
    ],



    // Information
    modelContentInformation: false,
    listLegend: [
      {
        text: "Active",
        color: "Green",
        img: require("@/assets/mapviewer/legend/station_icon_conti_green.svg"),
      },
      {
        text: "Inactive",
        color: "Grey",
        img: require("@/assets/mapviewer/legend/station_icon_conti_grey.svg"),
      },
      {
        text: "Transmission Delay",
        color: "Yellow",
        img: require("@/assets/mapviewer/legend/station_icon_conti_orange.svg"),
      },
      {
        text: "Manual Station",
        color: "Purple",
        img: require("@/assets/mapviewer/legend/station_icon_manual.png"),
      },
      {
        text: "Investigate Study",
        color: "Pink",
        img: require("@/assets/mapviewer/legend/station_icon_investStudy.png"),
      },
      {
        text: "Sample Point",
        color: "",
        img: require("@/assets/mapviewer/legend/sample_point_icon.png"),
      },
      {
        text: "Baseline Study Station",
        color: "Blue",
        img: require("@/assets/mapviewer/legend/station_icon_baseline.png"),
      },
    ],



    // Point of Interest
    dataPOI: [],
    poiSelected: false,



    // Station (Conti)
    dataStationConti: [],

    // Station (Manual)
    dataStationManual: [],

    // Station (Investigate Study)
    dataStationInvestStudy: [],
    modelSwitchSamplePointInvestStudy: false,
    loadingSwitchSamplePointInvestStudy: false,

    // Station (Baseline Study)
    dataStationBaselineStudy: [],

    // Identify
    modelContentStationIdentify: false,


  }),

  mounted() {

    this.getCurrentMode();
    this.getDataParams();
    this.getDataAllReadingStations();
    // this.setMap();

    // console.log(this.$store);

  },

  methods: {

    // General
    getCurrentMode() {

      // console.log(localStorage.clickButtonAMIS);

      if (localStorage.clickButtonAMIS == undefined) {

        bus.$emit("trigger - header", {
          focus: "clickButtonAMIS",
          a: true,
          m: false,
          is: false,
          bs: false,
        })

        let data = {
          a: true,
          m: false,
          is: false,
          bs: false,
        }

        localStorage.setItem("clickButtonAMIS", JSON.stringify(data));

      }
      else {

        let currentMode = JSON.parse(localStorage.clickButtonAMIS)

        this.stationCurrentMode = currentMode
        console.log(this.stationCurrentMode);

      }




    },

    getDataParams() {

      this.dataParams = [
        {
          module: "Conti",
          data: []
        },
        {
          module: "Manual",
          data: []
        },
        {
          module: "InvestStudy",
          data: []
        },
        {
          module: "BaselineStudy",
          data: []
        },
      ];

      axios.all([
        axios.get(this.globalUrl + "mqm2/miqims/params", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        }),
        axios.get(this.globalUrl + "mqm2/miqims/params_manual", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        }),
        axios.get(this.globalUrl + "mqm2/miqims/params_invstudy", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        }),
      ])
        .then(axios.spread((resConti, resManual, resInvestStudy) => {

          // console.log('Conti:', resConti, 'Manual:', resManual, 'Invest Study:', resInvestStudy,);

          for (let i in this.dataParams) {
            if (this.dataParams[i].module == "Conti") {
              this.dataParams[i].data = resConti.data;
            }
            else if (this.dataParams[i].module == "Manual") {
              this.dataParams[i].data = resManual.data;
            }
            else if (this.dataParams[i].module == "InvestStudy") {
              this.dataParams[i].data = resInvestStudy.data;
            }
            else if (this.dataParams[i].module == "BaselineStudy") {
              this.dataParams[i].data = resManual.data;
            }
          }


        }))
        .catch((error) => {
          console.log(error.response);
        });

    },

    getDataAllReadingStations() {

      this.dataStationConti = [];
      this.dataStationManual = [];
      this.dataStationInvestStudy = [];
      this.dataStationBaselineStudy = [];

      axios.all([
        axios.get(this.globalUrl + "mqm2/miqims/reading", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        }),
        axios.get(this.globalUrl + "mqm2/miqims/reading_manual", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        }),
        axios.get(this.globalUrl + "mqm2/miqims/reading_invstudy", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        }),
        axios.get(this.globalUrl + "mqm2/miqims/reading_baseline", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        }),
        axios.get(this.globalUrl + "mqm2/miqims/interest", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        }),
      ])
        .then(axios.spread((resConti, resManual, resInvestStudy, resBaselineStudy, resPOI) => {

          console.log('Conti:', resConti, 'Manual:', resManual, 'Invest Study:', resInvestStudy, 'Baseline Study:', resBaselineStudy, 'Point Of Interest:', resPOI);

          this.dataStationConti = resConti.data;
          this.dataStationManual = resManual.data;
          this.dataStationInvestStudy = resInvestStudy.data;
          this.dataStationBaselineStudy = resBaselineStudy.data;
          this.dataPOI = resPOI.data;

          // Create Standardize Data Station for All Station of Module
          for (let i in this.dataPOI) {
            for (let x in this.dataGlobalStations) {
              if (this.dataGlobalStations[x].module == "POI") {
                this.dataGlobalStations[x].data.push({
                  label: this.dataPOI[i].NAME,
                  stationID: this.dataPOI[i].ID,
                  caseID: "",
                  StationLocation: "",
                  StationState: "",
                  StationDate: "",
                  StationActive: "",
                  StationStatus: "",
                  Lat: this.dataPOI[i].LATITUDE,
                  Long: this.dataPOI[i].LONGITUDE,
                  Image: this.dataPOI[i].PICTURE_PATH,
                  StationImage: {
                    station: "",
                    deployment: "",
                    upstream: "",
                    downstream: "",
                  },
                  Others: this.dataPOI[i].DESCRIPTION,
                  Group: "POI",
                  DataOrigins: this.dataPOI[i]
                })
              }
            }
          }

          for (let i in this.dataStationConti) {
            for (let x in this.dataGlobalStations) {
              if (this.dataGlobalStations[x].module == "Conti") {
                this.dataGlobalStations[x].data.push({
                  label: this.dataStationConti[i].STATION_ID_A,
                  stationID: this.dataStationConti[i].STATION_ID_A,
                  caseID: "",
                  StationLocation: this.dataStationConti[i].LOCATION,
                  StationState: this.dataStationConti[i].STATE_NAME_A,
                  StationDate: this.dataStationConti[i].DATETIME_A,
                  StationActive: this.dataStationConti[i].STATION_STATUS == 1 ? true : false,
                  StationStatus: this.dataStationConti[i].GREEN == "TRUE" ? "Green" : this.dataStationConti[i].YELLOW == "TRUE" ? "Yellow" : this.dataStationConti[i].GREY == "TRUE" ? "Grey" : false,
                  Lat: this.dataStationConti[i].LATITUDE_F,
                  Long: this.dataStationConti[i].LONGITUDE_F,
                  Image: "",
                  StationImage: {
                    station: this.dataStationConti[i].STATION_PICTURE,
                    deployment: this.dataStationConti[i].DEPLOYMENT_PICTURE,
                    upstream: this.dataStationConti[i].UPSTREAM_PICTURE,
                    downstream: this.dataStationConti[i].DOWNSTREAM_PICTURE,
                  },
                  Others: null,
                  Group: "Conti",
                  DataOrigins: this.dataStationConti[i]
                })
              }
            }
          }

          for (let i in this.dataStationManual) {
            for (let x in this.dataGlobalStations) {
              if (this.dataGlobalStations[x].module == "Manual") {
                this.dataGlobalStations[x].data.push({
                  label: this.dataStationManual[i].STATION_ID_A,
                  stationID: this.dataStationManual[i].STATION_ID_A,
                  caseID: "",
                  StationLocation: this.dataStationManual[i].LOCATION,
                  StationState: this.dataStationManual[i].STATE_NAME_A,
                  StationDate: this.dataStationManual[i].DATETIME,
                  StationActive: "",
                  StationStatus: "Purple",
                  Lat: this.dataStationManual[i].LATITUDE,
                  Long: this.dataStationManual[i].LONGITUDE,
                  Image: "",
                  StationImage: {
                    station: this.dataStationManual[i].STATION_PICTURE,
                    deployment: this.dataStationManual[i].DEPLOYMENT_PICTURE,
                    upstream: this.dataStationManual[i].UPSTREAM_PICTURE,
                    downstream: this.dataStationManual[i].DOWNSTREAM_PICTURE,
                  },
                  Others: "",
                  Group: "Manual",
                  DataOrigins: this.dataStationManual[i]
                })
              }
            }
          }

          for (let i in this.dataStationInvestStudy) {
            for (let x in this.dataGlobalStations) {
              if (this.dataGlobalStations[x].module == "InvestStudy") {
                this.dataGlobalStations[x].data.push({
                  label: this.dataStationInvestStudy[i].REPORT_ID,
                  stationID: this.dataStationInvestStudy[i].REPORT_ID,
                  caseID: this.dataStationInvestStudy[i].REPORT_ID,
                  StationLocation: this.dataStationInvestStudy[i].LOCATION_DESC,
                  StationState: this.dataStationInvestStudy[i].STATE_NAME_A,
                  StationDate: this.dataStationInvestStudy[i].CREATED_DT,
                  StationActive: null,
                  StationStatus: "Pink",
                  Lat: this.dataStationInvestStudy[i].LATITUDE,
                  Long: this.dataStationInvestStudy[i].LONGITUDE,
                  Image: this.dataStationInvestStudy[i].PICTURE_PATH,
                  StationImage: {
                    station: "",
                    deployment: "",
                    upstream: "",
                    downstream: "",
                  },
                  Others: {
                    CaseName: this.dataStationInvestStudy[i].CASE_NAME,
                    CaseTitle: this.dataStationInvestStudy[i].CASE_TITLE,
                  },
                  Group: "InvestStudy",
                  DataOrigins: this.dataStationInvestStudy[i]
                })
                console.log(this.dataStationInvestStudy[i].CASE_NAME);
              }
            }
          }

          for (let i in this.dataStationBaselineStudy) {
            for (let x in this.dataGlobalStations) {
              if (this.dataGlobalStations[x].module == "BaselineStudy") {
                this.dataGlobalStations[x].data.push({
                  label: this.dataStationBaselineStudy[i].STATION_ID_A,
                  stationID: this.dataStationBaselineStudy[i].STATION_ID_A,
                  caseID: "",
                  StationLocation: this.dataStationBaselineStudy[i].LOCATION,
                  StationState: this.dataStationBaselineStudy[i].STATE_NAME_A,
                  StationDate: this.dataStationBaselineStudy[i].DATETIME,
                  StationActive: null,
                  StationStatus: "Blue",
                  Lat: this.dataStationBaselineStudy[i].LATITUDE,
                  Long: this.dataStationBaselineStudy[i].LONGITUDE,
                  Image: "",
                  StationImage: {
                    station: this.dataStationBaselineStudy[i].STATION_PICTURE,
                    deployment: this.dataStationBaselineStudy[i].DEPLOYMENT_PICTURE,
                    upstream: this.dataStationBaselineStudy[i].UPSTREAM_PICTURE,
                    downstream: this.dataStationBaselineStudy[i].DOWNSTREAM_PICTURE,
                  },
                  Others: null,
                  Group: "BaselineStudy",
                  DataOrigins: this.dataStationBaselineStudy[i]
                })
              }
            }
          }

          console.log("heeh", this.dataGlobalStations);

          this.setMap();

        }))
        .catch((error) => {
          console.log(error);

          this.setMap();
        });

    },



    // Generate Basic Map
    setMap() {

      // Create Map
      this.map = new Map({
        target: "map",
        layers: [
          this.baseMapLayer,
          // this.vectorPin
          // this.layerNegeriSelangor
        ],
        view: new View({
          zoom: 6,
          projection: 'EPSG:4326',
          center: [101.3766, 3.2343],
          constrainResolution: true
        }),
        controls: defaultControls({
          zoom: false
        }).extend([
          // [this.mapFullScreen]
          new FullScreen
          // this.mapFullScreen
        ]),
      })

      // Create View
      this.view = this.map.getView();
      this.view.fit(this.extentMalaysia);

      // this.nuzul();

      setTimeout(() => {
        this.setFunctionOnClickMap();
        this.loadAllLayerStations();
      }, 0)

    },

    setFunctionOnClickMap() {

      let that = this;

      this.map.on("click", function (evt) {

        let feature = this.forEachFeatureAtPixel(
          evt.pixel,
          (feature) => feature
        );

        if (feature != undefined) {

          console.log(feature);

          if (feature.values_.Group != "Sample Point") {

            that.detailsIdentifyVectorSelected = feature.values_;

            that.setContentIdentify(feature.values_);

            let long = feature.values_.long
            let lat = feature.values_.lat

            that.zoomFlyTo([long, lat], "center", "changeBasemapSatellite");

          }
          else {

            that.detailsSamplePointSelected = feature.values_;

          }

        }

      })

    },

    setContentIdentify(dataFeature) {

      // console.log(dataFeature.group);
      // console.log(this.dataParams);

      console.log(dataFeature);

      let data = dataFeature.data;
      console.log(data);

      this.modelContentExpansionIdentify = 0;

      this.tbodyContentParamsIndentify = [];

      if (dataFeature.group == "Conti") {

        let keys = Object.keys(data)

        let tempTbody = []
        for (let i in keys) {
          for (let x in this.dataParams) {
            if (this.dataParams[x].module == dataFeature.group) {
              for (let z in this.dataParams[x].data) {

                if (keys[i].includes("PARAM_") && keys[i].includes("_CLASS")) {
                  if (this.dataParams[x].data[z].reading == keys[i].split("PARAM_")[1].split("_CLASS")[0]) {
                    tempTbody.unshift({
                      status: "",
                      fullname: this.dataParams[x].data[z].text,
                      unit: this.dataParams[x].data[z].unit != "NULL" ? this.dataParams[x].data[z].unit : '',
                      acronym: this.dataParams[x].data[z].reading,
                      value_unit: "",
                      sub_index: "",
                      effluent_guideline: this.dataParams[x].data[z].limit,
                      flag: "",
                      type: this.dataParams[x].data[z].type != undefined ? this.dataParams[x].data[z].type : '',
                    })
                  }
                }
                else {
                  if (keys[i] != "BOD" && keys[i] != "COD" && keys[i] != "DO_SAT" && keys[i] != "NH3N" && keys[i] != "PH" && keys[i] != "TSS") {
                    if (this.dataParams[x].data[z].reading == keys[i]) {
                      tempTbody.push({
                        status: "",
                        fullname: this.dataParams[x].data[z].text,
                        unit: this.dataParams[x].data[z].unit != "NULL" ? this.dataParams[x].data[z].unit : '',
                        acronym: this.dataParams[x].data[z].reading,
                        value_unit: "",
                        sub_index: "",
                        effluent_guideline: this.dataParams[x].data[z].limit,
                        flag: "",
                        type: this.dataParams[x].data[z].type != undefined ? this.dataParams[x].data[z].type : ''
                      })
                    }
                  }
                }
              }
            }
          }
        }


        for (let i in keys) {
          for (let x in tempTbody) {
            if (keys[i].includes("_ACTIVE")) {
              if (data[keys[i]] != null) {
                if (tempTbody[x].acronym == keys[i.toUpperCase()].split("_ACTIVE")[0]) {
                  this.tbodyContentParamsIndentify.push(tempTbody[x])
                }
              }
            }
          }
        }


        for (let i in keys) {

          if (keys[i].includes("PARAM_") && keys[i].includes("_CLASS")) {
            for (let x in this.tbodyContentParamsIndentify) {
              if (this.tbodyContentParamsIndentify[x].acronym.toUpperCase() == keys[i].split("PARAM_")[1].split("_CLASS")[0]) {
                this.tbodyContentParamsIndentify[x]["status"] = data[keys[i]]
              }
            }
          }

          if (keys[i].includes("_SI_")) {
            for (let x in this.tbodyContentParamsIndentify) {
              if (this.tbodyContentParamsIndentify[x].acronym.toUpperCase() == keys[i].split("_SI_")[1]) {
                this.tbodyContentParamsIndentify[x]["sub_index"] = data[keys[i]]
              }
            }
          }

          if (keys[i].includes("_FLAG")) {
            for (let x in this.tbodyContentParamsIndentify) {
              if (this.tbodyContentParamsIndentify[x].acronym == keys[i].split("_FLAG")[0]) {
                if (data[keys[i].split("_FLAG")[0]] != null) {
                  this.tbodyContentParamsIndentify[x]["value_unit"] = data[keys[i].split("_FLAG")[0]]
                }
                this.tbodyContentParamsIndentify[x]["flag"] = data[keys[i]]
              }
            }
          }

          if (keys[i].includes("_ACTIVE")) {
            for (let x in this.tbodyContentParamsIndentify) {
              if (this.tbodyContentParamsIndentify[x].acronym == keys[i].split("_ACTIVE")[0]) {
                if (data[keys[i].split("_ACTIVE")[0]] != null) {
                  this.tbodyContentParamsIndentify[x]["value_unit"] = data[keys[i].split("_ACTIVE")[0]]
                }
              }
            }
          }

        }

        // params = [...new Set(params)];

        // console.log(params);
        // console.log("yeay", this.tbodyContentParamsIndentify);

      }
      else if (dataFeature.group == "Manual" || dataFeature.group == "BaselineStudy") {

        let keys = Object.keys(data);

        let tempTbody = [];
        for (let i in keys) {
          for (let x in this.dataParams) {
            if (this.dataParams[x].module == dataFeature.group) {
              for (let z in this.dataParams[x].data) {

                if (this.dataParams[x].data[z].type == "INSITU") {
                  if (this.dataParams[x].data[z].reading == keys[i]) {
                    tempTbody.push({
                      status: "",
                      fullname: this.dataParams[x].data[z].text,
                      unit: this.dataParams[x].data[z].unit != "NULL" ? this.dataParams[x].data[z].unit : '',
                      acronym: this.dataParams[x].data[z].reading,
                      value_unit: "",
                      sub_index: "",
                      effluent_guideline: this.dataParams[x].data[z].limit,
                      type: this.dataParams[x].data[z].type != undefined ? this.dataParams[x].data[z].type : ''
                    })
                  }
                }
                else if (this.dataParams[x].data[z].type == "LAB") {
                  if (this.dataParams[x].data[z].reading == keys[i]) {
                    tempTbody.push({
                      status: "",
                      fullname: this.dataParams[x].data[z].text,
                      unit: this.dataParams[x].data[z].unit != "NULL" ? this.dataParams[x].data[z].unit : '',
                      acronym: this.dataParams[x].data[z].reading,
                      value_unit: "",
                      sub_index: "",
                      effluent_guideline: this.dataParams[x].data[z].limit,
                      type: this.dataParams[x].data[z].type != undefined ? this.dataParams[x].data[z].type : ''
                    })
                  }
                }

              }
            }
          }
        }


        for (let i in keys) {
          for (let x in tempTbody) {
            if (keys[i].includes("_ACTIVE")) {
              if (data[keys[i]] != null) {
                if (tempTbody[x].acronym == keys[i].split("_ACTIVE")[0]) {
                  this.tbodyContentParamsIndentify.push(tempTbody[x])
                }
              }
            }
          }
        }


        for (let i in keys) {

          if (keys[i].includes("PARAM_") && keys[i].includes("_CLASS")) {
            for (let x in this.tbodyContentParamsIndentify) {
              if (this.tbodyContentParamsIndentify[x].acronym.toUpperCase() == keys[i].split("PARAM_")[1].split("_CLASS")[0]) {
                this.tbodyContentParamsIndentify[x]["status"] = data[keys[i]]
              }
            }
          }

          if (keys[i].includes("_CLASS")) {
            for (let x in this.tbodyContentParamsIndentify) {
              if (this.tbodyContentParamsIndentify[x].acronym.toUpperCase() == keys[i].split("_CLASS")[0]) {
                this.tbodyContentParamsIndentify[x]["status"] = data[keys[i]]
              }
            }
          }

          if (keys[i].includes("_SI_")) {
            for (let x in this.tbodyContentParamsIndentify) {
              if (this.tbodyContentParamsIndentify[x].acronym.toUpperCase() == keys[i].split("_SI_")[1]) {
                this.tbodyContentParamsIndentify[x]["sub_index"] = data[keys[i]]
              }
            }
          }

          if (keys[i].includes("_LAB")) {
            for (let x in this.tbodyContentParamsIndentify) {
              if (this.tbodyContentParamsIndentify[x].acronym.toUpperCase() == keys[i].split("_LAB")[0]) {
                this.tbodyContentParamsIndentify[x]["value_unit"] = data[keys[i].split("_LAB")[0]]
              }
            }
          }

          for (let x in this.tbodyContentParamsIndentify) {
            if (this.tbodyContentParamsIndentify[x].acronym == keys[i]) {
              if (data[keys[i]] != null) {
                this.tbodyContentParamsIndentify[x]["value_unit"] = data[keys[i]]
              }
            }
          }

        }

      }


      console.log(this.tbodyContentParamsIndentify);

      this.modelContentStationIdentify = true;

    },

    closeModelContentStationIdentify() {

      // Remove Semua Layer Sample Point
      if (this.modelSwitchSamplePointInvestStudy == true) {
        this.modelSwitchSamplePointInvestStudy = false;
        this.swichingSamplePointInvestStudy(null, false);
      }

      this.modelContentStationIdentify = false;

    },

    loadAllLayerStations() {

      let that = this;

      // Create Vector Source & Vector Layer secara dynamic for all module (Conti, Manial & etc)
      for (let i in this.dataGlobalStations) {

        this.objGeom["geoJson_" + this.dataGlobalStations[i].module] = { "type": "FeatureCollection", "features": [] };

        this.objGeom["source_" + this.dataGlobalStations[i].module] = new VectorSource({ wrapX: false });

        this.objGeom["layer_" + this.dataGlobalStations[i].module] = new VectorLayer();

      }

      // Create Geojson features point of data station for each module
      let keysAllGeoJson = Object.keys(this.objGeom).filter(x => x.includes('geoJson_'));
      for (let i in keysAllGeoJson) {
        for (let x in this.dataGlobalStations) {
          if (this.dataGlobalStations[x].module == keysAllGeoJson[i].split("geoJson_")[1]) {

            for (let y in this.dataGlobalStations[x].data) {

              this.objGeom[keysAllGeoJson[i]].features.push({
                type: "Feature",
                id: this.dataGlobalStations[x].data[y].stationID == null ? '' : this.dataGlobalStations[x].data[y].stationID,
                geometry: {
                  type: "Point",
                  coordinates: [
                    this.dataGlobalStations[x].data[y].Long,
                    this.dataGlobalStations[x].data[y].Lat,
                  ]
                },
                geometry_name: "geom",
                properties: {
                  label: this.dataGlobalStations[x].data[y].label == null ? '' : this.dataGlobalStations[x].data[y].label,
                  stationID: this.dataGlobalStations[x].data[y].stationID == null ? '' : this.dataGlobalStations[x].data[y].stationID,
                  caseID: this.dataGlobalStations[x].data[y].caseID == null ? '' : this.dataGlobalStations[x].data[y].caseID,
                  location: this.dataGlobalStations[x].data[y].StationLocation == null ? '' : this.dataGlobalStations[x].data[y].StationLocation,
                  state: this.dataGlobalStations[x].data[y].StationState == null ? '' : this.dataGlobalStations[x].data[y].StationState,
                  datetime: this.dataGlobalStations[x].data[y].StationDate == null ? '' : this.dataGlobalStations[x].data[y].StationDate,
                  stationActive: this.dataGlobalStations[x].data[y].StationActive == null ? '' : this.dataGlobalStations[x].data[y].StationActive,
                  stationStatus: this.dataGlobalStations[x].data[y].StationStatus == null ? '' : this.dataGlobalStations[x].data[y].StationStatus,
                  lat: this.dataGlobalStations[x].data[y].Lat == null ? '' : this.dataGlobalStations[x].data[y].Lat,
                  long: this.dataGlobalStations[x].data[y].Long == null ? '' : this.dataGlobalStations[x].data[y].Long,
                  img: this.dataGlobalStations[x].data[y].Image == null ? '' : this.dataGlobalStations[x].data[y].Image,
                  stationImage: this.dataGlobalStations[x].data[y].StationImage == null ? '' : this.dataGlobalStations[x].data[y].StationImage,
                  others: this.dataGlobalStations[x].data[y].Others == null ? '' : this.dataGlobalStations[x].data[y].Others,
                  group: this.dataGlobalStations[x].data[y].Group == null ? '' : this.dataGlobalStations[x].data[y].Group,
                  data: this.dataGlobalStations[x].data[y].DataOrigins
                }
              })

            }

          }
        }
      }

      // Add GeoJSON feature to Vector Source


      for (let i in this.dataGlobalStations) {

        // console.log(this.objGeom["geoJson_"+this.dataGlobalStations[i].module]);

        this.objGeom["feature_" + this.dataGlobalStations[i].module] = new GeoJSON().readFeatures(this.objGeom["geoJson_" + this.dataGlobalStations[i].module]);

        // ** Set Icon for each feature point
        for (let x in this.dataGlobalStations[i].data) {

          let imgVector;
          for (let y in this.listLegend) {
            if (this.dataGlobalStations[i].data[x].Group == "POI") {
              imgVector = new Circle({
                radius: 5,
                fill: new Fill({
                  color: '#ff0800'
                }),
                stroke: new Stroke({
                  color: '#520301',
                  width: 2
                }),
              });
            }
            else {
              if (this.dataGlobalStations[i].data[x].StationStatus == this.listLegend[y].color) {
                imgVector = new Icon({
                  anchor: this.dataGlobalStations[i].module == "Conti" ? [0.5, 46] : this.dataGlobalStations[i].module == "InvestStudy" ? [0.5, 46] : [0, 0],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'pixels',
                  src: this.listLegend[y].img,
                });
              }
            }
          }

          let textVector;

          // Set label style for each feature
          textVector = new Text({
            textAlign: "center",
            textBaseline: "bottom",
            font: "bold 14px/1 Arial",
            text: this.dataGlobalStations[i].data[x].label,
            fill: new Fill({ color: "#000000" }),
            stroke: new Stroke({ color: "#ffffff", width: 5 }),
            offsetX: this.dataGlobalStations[i].module == "Conti" ? 0 : this.dataGlobalStations[i].module == "InvestStudy" ? 0 : 23,
            offsetY: this.dataGlobalStations[i].module == "Conti" ? -50 : this.dataGlobalStations[i].module == "InvestStudy" ? -50 : this.dataGlobalStations[i].module == "POI" ? -12 : 0,
            placement: "point",
            maxAngle: 45,
            rotation: 0,
          })

          let style = new Style({
            image: imgVector,
            text: textVector
          })

          this.objGeom["feature_" + this.dataGlobalStations[i].module][x].setStyle(style)

        }


        //  ===============Ni code kalau nk set style vector layer tu sendiri, bukan style feature ==============================

        // this.objGeom["style_point_"+this.dataGlobalStations[i].module] = new Style({
        //     image: imgVector,
        //     text: textVector
        // })

        // for(let x in this.objGeom["feature_"+this.dataGlobalStations[i].module]){
        //   this.objGeom["feature_"+this.dataGlobalStations[i].module].setStyle()
        // }

        // ==================================================================================================================


        this.objGeom["source_" + this.dataGlobalStations[i].module].addFeatures(this.objGeom["feature_" + this.dataGlobalStations[i].module]);

        this.objGeom["layer_" + this.dataGlobalStations[i].module] = new VectorLayer({
          source: this.objGeom["source_" + this.dataGlobalStations[i].module],
          // style: this.objGeom["style_point_"+this.dataGlobalStations[i].module], //Style Untuk Vector Layer (Bukan Feature)
          className: this.dataGlobalStations[i].module
        });

      }


      // Load/Add All Vector Layers Station into map
      for (let i in this.dataGlobalStations) {
        this.map.addLayer(this.objGeom["layer_" + this.dataGlobalStations[i].module]);
      }

      setTimeout(() => {
        console.log(this.objGeom);
        this.setVisibleStationLayer();
      }, 0)

      // console.log(this.dataGlobalStations);
      // console.log(this.objGeom);

    },

    setVisibleStationLayer() {

      // Remove Semua Layer Sample Point First
      if (this.modelSwitchSamplePointInvestStudy == true) {
        this.modelSwitchSamplePointInvestStudy = false;
        this.swichingSamplePointInvestStudy(null, false);
      }


      // Set Layer Mana Should be visible based on current button click
      if (this.map.getLayers().array_.length > 1) {

        // Start array satu sebab index 0 adalah layer basemap
        for (let i = 1; i < this.map.getLayers().array_.length; i++) {

          this.map.getLayers().array_[i].setVisible(false);

          if (this.poiSelected == true) {
            if (this.map.getLayers().array_[i].className_ == "POI") {
              this.map.getLayers().array_[i].setVisible(true);
            }
          }
          if (this.stationCurrentMode.a == true && this.stationCurrentMode.m == true) {
            if (this.map.getLayers().array_[i].className_ == "Conti"
              || this.map.getLayers().array_[i].className_ == "Manual") {
              this.map.getLayers().array_[i].setVisible(true);
            }
          }
          if (this.stationCurrentMode.a == true && this.stationCurrentMode.m == false) {
            if (this.map.getLayers().array_[i].className_ == "Conti") {
              this.map.getLayers().array_[i].setVisible(true);
            }
          }
          if (this.stationCurrentMode.a == false && this.stationCurrentMode.m == true) {
            if (this.map.getLayers().array_[i].className_ == "Manual") {
              this.map.getLayers().array_[i].setVisible(true);
            }
          }
          if (this.stationCurrentMode.is == true) {
            if (this.map.getLayers().array_[i].className_ == "InvestStudy") {
              this.map.getLayers().array_[i].setVisible(true);
            }
          }
          if (this.stationCurrentMode.bs == true) {
            if (this.map.getLayers().array_[i].className_ == "BaselineStudy") {
              this.map.getLayers().array_[i].setVisible(true);
            }
          }

        }


        setTimeout(() => {

          // Set Extent Based on Vector Layer that visible==true
          let layerVectorTrue = []
          this.map.getLayers().forEach(function (layer) {
            if (layer.className_ != "ol-layer") {
              if (layer.values_.visible == true) {
                layerVectorTrue.push(layer)
              }
            }
          });
          let extent = olExtent.createEmpty();
          // console.log(layerVectorTrue);
          if (layerVectorTrue.length != 0) {
            for (let i in layerVectorTrue) {
              olExtent.extend(extent, layerVectorTrue[i].getSource().getExtent());
            }
            this.map.getView().fit(extent, { size: this.map.getSize(), maxZoom: 16 });
          }
          else {
            this.map.getView().fit(this.extentMalaysia, { size: this.map.getSize() });
          }

        }, 0)


        // console.log("Layerss",this.map.getLayers().array_);



      }

    },


    // Search Function
    map_search() {

      this.listSearchStates = [];
      this.modelSearchStateSelected = "";
      this.listSearchStation = [];
      this.modelSearchStationSelected = "";

      if (this.stationCurrentMode.a == true && this.stationCurrentMode.m == true) {

        this.modelRadioSearchLayer = ""

      }
      else if (this.stationCurrentMode.a == true && this.stationCurrentMode.m == false) {

        for (let i in this.dataStationConti) {
          this.listSearchStates.push(this.dataStationConti[i].STATE_NAME_A)
        }
        this.listSearchStates.sort()

      }
      else if (this.stationCurrentMode.a == false && this.stationCurrentMode.m == true) {

        for (let i in this.dataStationManual) {
          this.listSearchStates.push(this.dataStationManual[i].STATE_NAME_A)
        }
        this.listSearchStates.sort()

      }
      else if (this.stationCurrentMode.is == true) {

        for (let i in this.dataStationInvestStudy) {
          this.listSearchStates.push(this.dataStationInvestStudy[i].STATE_NAME_A)
        }
        this.listSearchStates.sort()

      }
      else if (this.stationCurrentMode.bs == true) {

        for (let i in this.dataStationBaselineStudy) {
          this.listSearchStates.push(this.dataStationBaselineStudy[i].STATE_NAME_A)
        }
        this.listSearchStates.sort()

      }

      this.modelContentSearch = true;

    },

    selectRadioSearchLayer(mod) {

      this.listSearchStates = [];
      this.modelSearchStateSelected = "";
      this.listSearchStation = [];
      this.modelSearchStationSelected = "";

      if (mod == "Conti") {

        for (let i in this.dataStationConti) {
          this.listSearchStates.push(this.dataStationConti[i].STATE_NAME_A)
        }
        this.listSearchStates.sort()

      }
      else if (mod == "Manual") {

        for (let i in this.dataStationManual) {
          this.listSearchStates.push(this.dataStationManual[i].STATE_NAME_A)
        }
        this.listSearchStates.sort()

      }

    },

    selectSearchStates(state) {

      this.listSearchStation = [];
      this.modelSearchStationSelected = "";

      if (this.stationCurrentMode.a == true && this.stationCurrentMode.m == true) {

        if (this.modelRadioSearchLayer == "Conti") {

          for (let i in this.dataStationConti) {
            if (state == this.dataStationConti[i].STATE_NAME_A) {
              this.listSearchStation.push(this.dataStationConti[i].STATION_ID_A + " - " + this.dataStationConti[i].LOCATION);
            }

          }
          this.listSearchStation.sort()

        }
        else if (this.modelRadioSearchLayer == "Manual") {

          for (let i in this.dataStationManual) {
            if (state == this.dataStationManual[i].STATE_NAME_A) {
              this.listSearchStation.push(this.dataStationManual[i].STATION_ID_A + " - " + this.dataStationManual[i].LOCATION);
            }

          }
          this.listSearchStation.sort()

        }

      }
      else if (this.stationCurrentMode.a == true && this.stationCurrentMode.m == false) {

        for (let i in this.dataStationConti) {
          if (state == this.dataStationConti[i].STATE_NAME_A) {
            this.listSearchStation.push(this.dataStationConti[i].STATION_ID_A + " - " + this.dataStationConti[i].LOCATION);
          }

        }
        this.listSearchStation.sort()

      }
      else if (this.stationCurrentMode.a == false && this.stationCurrentMode.m == true) {

        for (let i in this.dataStationManual) {
          if (state == this.dataStationManual[i].STATE_NAME_A) {
            this.listSearchStation.push(this.dataStationManual[i].STATION_ID_A + " - " + this.dataStationManual[i].LOCATION);
          }

        }
        this.listSearchStation.sort()

      }
      else if (this.stationCurrentMode.is == true) {

        for (let i in this.dataStationInvestStudy) {
          if (state == this.dataStationInvestStudy[i].STATE_NAME_A) {
            this.listSearchStation.push(this.dataStationInvestStudy[i].STATION_ID_A + " - " + this.dataStationInvestStudy[i].LOCATION_DESC);
          }

        }
        this.listSearchStation.sort()

      }
      else if (this.stationCurrentMode.bs == true) {

        for (let i in this.dataStationBaselineStudy) {
          if (state == this.dataStationBaselineStudy[i].STATE_NAME_A) {
            this.listSearchStation.push(this.dataStationBaselineStudy[i].STATION_ID_A + " - " + this.dataStationBaselineStudy[i].LOCATION);
          }

        }
        this.listSearchStation.sort()

      }


      // Zoom States
      for (let i in this.extentState) {
        if (this.extentState[i].name == state.toUpperCase()) {
          this.zoomFlyTo(this.extentState[i].extent, "extent", "changeBasemapTopo");
        }
      }

    },

    selectSearchStation(station) {

      let stationID = station.split(" - ")[0];

      // console.log(stationID);
      // console.log(this.map.getLayers().array_);

      let lat = 0;
      let long = 0;
      for (let i in this.map.getLayers().array_) {
        if (this.map.getLayers().array_[i].className_ != "ol-layer") {
          for (let x in this.map.getLayers().array_[i].getSource().getFeatures()) {
            if (this.map.getLayers().array_[i].getSource().getFeatures()[x].values_.stationID == stationID) {
              lat = this.map.getLayers().array_[i].getSource().getFeatures()[x].values_.lat
              long = this.map.getLayers().array_[i].getSource().getFeatures()[x].values_.long
            }
          }
        }
      }

      setTimeout(() => {
        this.zoomFlyTo([long, lat], "center", "zoomWithoutFly");
      }, 0)

    },


    // Home Function
    map_home() {

      // console.log("masuk");
      this.setVisibleStationLayer()

      setTimeout(() => {
        let data = { name: "Topographic Map" }
        this.map_basemap(data);
        this.modelContentStationIdentify = false;

        // Set Extent Based on Vector Layer that visible==true
        let layerVectorTrue = []
        this.map.getLayers().forEach(function (layer) {
          if (layer.className_ != "ol-layer") {
            if (layer.values_.visible == true) {
              layerVectorTrue.push(layer)
            }
          }
        });
        let extent = olExtent.createEmpty();
        // console.log(layerVectorTrue);
        if (layerVectorTrue.length != 0) {
          for (let i in layerVectorTrue) {
            olExtent.extend(extent, layerVectorTrue[i].getSource().getExtent());
          }
          this.map.getView().fit(extent, { size: this.map.getSize(), maxZoom: 16 });
        }
        else {
          this.map.getView().fit(this.extentMalaysia, { size: this.map.getSize() });
        }

        // this.map.getView().fit(this.extentMalaysia, { size: this.map.getSize() });


      }, 0)

    },

    // Basemap Function
    map_basemap(data) {

      this.basemapSelected = data.name;

      let arrBasemap = this.baseMapLayer.values_.layers.array_;
      // console.log(this.baseMapLayer.values_.layers);
      for (let i in arrBasemap) {
        if (arrBasemap[i].values_.title == data.name) {
          arrBasemap[i].setVisible(true);
        }
        else {
          arrBasemap[i].setVisible(false);
        }
      }

    },

    // Point of Interest
    map_poi() {

      this.poiSelected = !this.poiSelected;

      this.setVisibleStationLayer();

    },

    // Station.. Conti, Manual, Investigative Study, Baseline Study
    map_station(mod) {

      this.modelContentStationIdentify = false;
      this.modelContentExpansionIdentify = 1;

      if (mod == "Conti") {
        this.stationCurrentMode.a = !this.stationCurrentMode.a;
        this.stationCurrentMode.is = false;
        this.stationCurrentMode.bs = false;
      }
      else if (mod == "Manual") {
        this.stationCurrentMode.m = !this.stationCurrentMode.m;
        this.stationCurrentMode.is = false;
        this.stationCurrentMode.bs = false;
      }
      else if (mod == "Invest Study") {
        this.stationCurrentMode.a = false;
        this.stationCurrentMode.m = false;
        this.stationCurrentMode.is = !this.stationCurrentMode.is;
        this.stationCurrentMode.bs = false;
      }
      else if (mod == "Baseline Study") {
        this.stationCurrentMode.a = false;
        this.stationCurrentMode.m = false;
        this.stationCurrentMode.is = false;
        this.stationCurrentMode.bs = !this.stationCurrentMode.bs;
      }

      setTimeout(() => {

        this.setVisibleStationLayer();

        bus.$emit("trigger - header", {
          focus: "clickButtonAMIS",
          a: this.stationCurrentMode.a,
          m: this.stationCurrentMode.m,
          is: this.stationCurrentMode.is,
          bs: this.stationCurrentMode.bs,
        })

        let data = {
          a: this.stationCurrentMode.a,
          m: this.stationCurrentMode.m,
          is: this.stationCurrentMode.is,
          bs: this.stationCurrentMode.bs,
        }

        localStorage.setItem("clickButtonAMIS", JSON.stringify(data));

      }, 100)

    },

    // Investigate Study
    swichingSamplePointInvestStudy(caseid, model) {

      this.loadingSwitchSamplePointInvestStudy = true;

      if (model == true) {

        let dataSelectedSamplePoint = []

        axios.get(this.globalUrl + 'mqm2/miqims/edit_getinfofirst_invstudy?caseid=' + caseid, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
        })
          .then((response) => {

            let data = response.data;

            dataSelectedSamplePoint = data["pointinfo"];

            // Create Data Layer
            setTimeout(() => {

              let dataLayerSamplePoint = [];
              for (let i in dataSelectedSamplePoint) {
                dataLayerSamplePoint.push({
                  Label: dataSelectedSamplePoint[i].samplingpoint,
                  ID: dataSelectedSamplePoint[i].samplingpoint,
                  Location: dataSelectedSamplePoint[i].locationdescription,
                  State: dataSelectedSamplePoint[i].pointstate,
                  Date: dataSelectedSamplePoint[i].date,
                  Status: "",
                  Lat: dataSelectedSamplePoint[i].pointlatitude,
                  Long: dataSelectedSamplePoint[i].pointlongitude,
                  Image: dataSelectedSamplePoint[i].picturepath,
                  Others: "",
                  Group: "Sample Point",
                  GeomType: "Point",
                })
              }

              setTimeout(() => {

                let iconPin = require("@/assets/mapviewer/legend/sample_point_icon.png");
                let anchorStatus = true;
                let color = "#f018d1"

                console.log(dataLayerSamplePoint);

                this.loadAnyLayer(dataLayerSamplePoint, "image", iconPin, anchorStatus, color);

              }, 0)

            }, 0)


          })
          .catch(error => {
            console.log(error);
          })

      }
      else {

        // console.log(this.map.getLayers().array_);

        let layerSamplePoint = null;

        for (let i in this.map.getLayers().array_) {
          if (this.map.getLayers().array_[i].className_ == "Sample Point") {
            layerSamplePoint = this.map.getLayers().array_[i];
          }
        }

        if (layerSamplePoint != null) {
          this.removeAnyLayer(layerSamplePoint)
        }

      }



    },



    // +++++++++++++++++++++++++++++++ Future Use (Nuzul) +++++++++++++++++++++++++++++++++++++++++


    loadAnyLayer(datalayer, imgVectorType, imageUse, anchorStatus, colorVector) {

      let geoJsonLayer = { "type": "FeatureCollection", "features": [] };

      let sourceLayer = new VectorSource({ wrapX: false });

      let layerVector = new VectorLayer();

      for (let i in datalayer) {
        geoJsonLayer["features"].push({
          type: "Feature",
          id: datalayer[i].ID,
          geometry: {
            type: datalayer[i].GeomType,
            coordinates: [
              datalayer[i].Long,
              datalayer[i].Lat,
            ]
          },
          geometry_name: "geom",
          properties: {
            ID: datalayer[i].ID,
            Location: datalayer[i].Location,
            State: datalayer[i].State,
            Date: datalayer[i].Date,
            Status: datalayer[i].Status,
            Lat: datalayer[i].Lat,
            Long: datalayer[i].Long,
            Image: datalayer[i].Image,
            Others: datalayer[i].Others,
            Group: datalayer[i].Group,
          }
        })
      }

      let featureLayer = new GeoJSON().readFeatures(geoJsonLayer);

      for (let i in datalayer) {

        let imgVector = null;
        if (imgVectorType == "image") {
          imgVector = new Icon({
            anchor: anchorStatus == true ? [0.5, 46] : [0, 0],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: imageUse,
          });
        }
        else {
          imgVector = new Circle({
            radius: 5,
            fill: new Fill({
              color: colorVector
            }),
            stroke: new Stroke({
              color: '#000000',
              width: 2
            }),
          });
        }

        // Set label style for each feature
        let textVector;
        textVector = new Text({
          textAlign: "center",
          textBaseline: "bottom",
          font: "bold 14px/1 Arial",
          text: datalayer[i].Label,
          fill: new Fill({ color: "#000000" }),
          stroke: new Stroke({ color: "#ffffff", width: 5 }),
          offsetX: 0,
          offsetY: -35,
          placement: "point",
          maxAngle: 45,
          rotation: 0,
        })

        let style_point = new Style({
          image: imgVector,
          text: textVector
        })

        featureLayer[i].setStyle(style_point)

      }


      sourceLayer.addFeatures(featureLayer);



      layerVector = new VectorLayer({
        source: sourceLayer,
        // style: style_point,
        className: datalayer[0].Group
      });

      this.map.addLayer(layerVector);

      for (let i in this.map.getLayers().array_) {
        if (this.map.getLayers().array_[i].className_ != "ol-layer") {
          // console.log(this.map.getLayers().array_[i].getSource().getFeatures());
        }
      }

      setTimeout(() => {

        // Set Extent Based on Vector Layer of sample point
        let layerVectorTrue = []
        this.map.getLayers().forEach(function (layer) {

          if (layer.className_ == datalayer[0].Group) {
            if (layer.values_.visible == true) {
              layerVectorTrue.push(layer)
            }
          }
        });
        let extent = olExtent.createEmpty();
        // console.log(layerVectorTrue);
        if (layerVectorTrue.length != 0) {
          for (let i in layerVectorTrue) {
            olExtent.extend(extent, layerVectorTrue[i].getSource().getExtent());
          }
          this.map.getView().fit(extent, { size: this.map.getSize(), maxZoom: 14 });
        }
        else {
          this.map.getView().fit(this.extentMalaysia, { size: this.map.getSize() });
        }

        this.loadingSwitchSamplePointInvestStudy = false;

      }, 0)


    },

    removeAnyLayer(layer) {

      this.map.removeLayer(layer);

      this.loadingSwitchSamplePointInvestStudy = false;

    },

    openNewTab(src) {
      window.open(src);
    },


    zoomFlyTo(location, mode, specialRequest) {

      this.view.setZoom(16);
      const duration = 3000;

      let parts = 2;
      let called = false;

      let zoom = null;
      let center = null;
      if (mode == "center") {
        zoom = this.view.getZoom();
        center = location;
      }
      else if (mode == "extent") {
        let resolution = this.view.getResolutionForExtent(location);
        zoom = this.view.getZoomForResolution(resolution);
        center = olExtent.getCenter(location);
      }

      function callback(complete) {
        --parts;
        if (called) {
          return;
        }
        if (parts === 0 || !complete) {
          called = true;
        }
      }


      let val = mode == "extent" ? 2 : 8;


      if (specialRequest != "zoomWithoutFly") {

        this.view.animate(
          {
            center: center,
            duration: duration,
          },
          // callback
        );

        this.view.animate(
          {
            zoom: zoom - val,
            duration: duration / 2,
          },
          {
            zoom: zoom,
            duration: duration / 2,
          },
          // callback
        );

      }
      else {
        this.view.animate(
          {
            center: center,
            zoom: 18,
            duration: duration,
          },
          // callback
        );
      }


      if (specialRequest != undefined) {
        if (specialRequest == "changeBasemapSatellite" || specialRequest == "zoomWithoutFly") {
          let data = { name: "Esri Satellite Map" }
          this.map_basemap(data);
        }
        else if (specialRequest == "changeBasemapTopo") {
          let data = { name: "Topographic Map" }
          this.map_basemap(data);
        }
      }

    },


    // Get Current Extent
    // nuzul(){
    //   this.map.on('moveend', this.getCurrentExtent);
    // },
    // getCurrentExtent(){
    //   console.log(this.map.getView().calculateExtent());
    // }

  },

};
</script>

<style lang="scss">
@import '~scss/main';

.map {
  height: 100%;
}

.top-left {
  position: absolute;
  top: 20px;
  left: 20px;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

.top-right {
  position: absolute !important;
  top: 20px !important;
  right: 20px;
  z-index: 10;
}

.v-list-item-basemap {
  cursor: pointer;
}

.v-list-item-basemap:hover {
  background: #dcdcdc;
  cursor: pointer;
}

// Hide Default Button Openlayers
.ol-full-screen.ol-unselectable.ol-control {
  display: none;
}

.in-situ {
  background-image: linear-gradient(to bottom, #ddfdff, #aef2f7) !important;
  color: black !important;
  font-weight: bold !important;
  border: 0.1px solid rgb(0, 0, 0) !important;
}

// .asd:nth-child()

.laboratory {
  background-image: linear-gradient(to bottom, #e89ee8, #fc9df4) !important;
  color: black !important;
  font-weight: bold !important;
  border: 0.1px solid rgb(0, 0, 0) !important;
}
</style>


